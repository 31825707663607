<template>
    <svg id="logo-meditbe" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.66 257.55">
							<path d="M114.19,70.94v110.58h-17.78v-75.74l-33.08,75.74h-12.32L17.78,105.79v75.74H0v-110.58h19.21l38.07,86.71,37.91-86.71h19.01Z" style="stroke-width: 0px;" class="letter"></path>
							<path d="M219.73,144.47h-65.68c.26,6.43,3,12.5,7.64,16.87,4.6,4.26,10.64,6.54,16.85,6.37,8.59.6,16.66-4.27,20.28-12.25h19.21c-2.53,7.96-7.48,14.89-14.12,19.79-7.39,5.33-16.29,8.04-25.35,7.71-7.72.13-15.33-1.82-22.07-5.66-6.48-3.72-11.78-9.23-15.32-15.91-3.81-7.32-5.72-15.5-5.55-23.78-.17-8.26,1.68-16.44,5.38-23.79,3.43-6.67,8.68-12.16,15.13-15.82,6.86-3.81,14.58-5.72,22.39-5.57,7.56-.14,15.03,1.73,21.68,5.41,6.26,3.52,11.4,8.8,14.82,15.2,3.64,6.92,5.46,14.67,5.3,22.51.02,2.99-.18,5.97-.6,8.93M201.82,129.83c.12-6.16-2.45-12.05-7.02-16.07-4.74-4.1-10.8-6.25-17.01-6.05-5.8-.12-11.43,2.01-15.75,5.97-4.48,4.2-7.26,9.96-7.8,16.15h47.59Z" style="stroke-width: 0px;" class="letter"></path>
							<path d="M241.04,113.9c6.86-13.39,20.53-21.69,35.34-21.48,5.74.02,11.4,1.3,16.62,3.74,5.07,2.19,9.54,5.6,13.03,9.94v-42.32h17.93v117.74h-17.93v-13.2c-3.14,4.44-7.29,8.05-12.09,10.5-5.48,2.84-11.56,4.26-17.71,4.14-7.23.07-14.35-1.94-20.51-5.81-6.25-3.94-11.33-9.55-14.67-16.23-3.67-7.3-5.52-15.42-5.38-23.64-.15-8.13,1.7-16.17,5.38-23.39M302.36,121.86c-2.23-4.22-5.54-7.75-9.6-10.18-3.89-2.31-8.3-3.51-12.8-3.48-4.48-.02-8.9,1.15-12.8,3.42-4.04,2.37-7.36,5.84-9.59,10.01-2.54,4.81-3.81,10.21-3.67,15.67-.11,5.54,1.14,11.01,3.67,15.91,2.22,4.32,5.56,7.92,9.67,10.42,3.85,2.35,8.24,3.58,12.71,3.58,4.5.02,8.92-1.17,12.8-3.48,4.06-2.45,7.38-6.01,9.59-10.26,2.53-4.87,3.8-10.32,3.67-15.83.14-5.49-1.11-10.92-3.64-15.77h-.02Z" style="stroke-width: 0px;" class="letter"></path>
							<path d="M350.65,78.89c-4.48-4.5-4.54-11.85-.12-16.42,2.16-2.25,5.14-3.51,8.23-3.47,2.99-.05,5.87,1.15,7.95,3.34,4.37,4.61,4.37,11.92,0,16.54-2.08,2.18-4.96,3.39-7.95,3.35-3.04.07-5.98-1.13-8.11-3.34ZM367.5,181.52h-17.79v-87.67h17.79v87.67Z" style="stroke-width: 0px;" class="letter"></path>
							<path d="M413.53,108.65v48.53c-.24,2.58.58,5.15,2.26,7.09,2.23,1.65,4.98,2.42,7.72,2.14h10.92v15.12h-14.04c-6.61.46-13.16-1.59-18.4-5.72-4.69-4.96-7.03-11.75-6.4-18.61v-48.53h-10.14v-14.8h10.14v-21.79h17.93v21.8h20.91v14.8h-20.89l-.02-.02Z" style="stroke-width: 0px;" class="letter"></path>
							<path d="M694.35,134.11c7.39,9.45,8.12,22.63,1.79,32.85-3.06,4.68-7.36,8.38-12.4,10.66-5.93,2.72-12.38,4.06-18.88,3.92h-43.38v-110.59h41.36c6.54-.18,13.04,1.13,19.03,3.82,4.89,2.18,9.05,5.74,12.02,10.26,2.7,4.29,4.12,9.29,4.05,14.4.2,5.73-1.56,11.37-4.99,15.91-3.5,4.36-8.16,7.63-13.42,9.38,5.91,1.13,11.2,4.47,14.8,9.38M639.29,117.4h22c4.88.29,9.7-1.14,13.66-4.06,3.35-2.89,5.18-7.22,4.92-11.7.22-4.46-1.58-8.78-4.92-11.69-3.91-3.02-8.76-4.52-13.66-4.22h-22v31.67ZM677.51,162.27c3.49-3.11,5.39-7.69,5.15-12.41.21-4.93-1.8-9.67-5.46-12.89-4.1-3.35-9.26-5.05-14.51-4.79h-23.41v34.53h24.03c5.1.31,10.13-1.27,14.19-4.44h.01,0Z" style="stroke-width: 0px;" class="letter"></path>
							<path d="M801.32,144.47h-65.68c.26,6.43,3.01,12.5,7.65,16.87,4.6,4.26,10.64,6.54,16.85,6.37,8.59.6,16.66-4.27,20.28-12.25h19.21c-2.54,7.96-7.49,14.91-14.13,19.81-7.39,5.33-16.29,8.04-25.35,7.72-7.72.12-15.32-1.83-22.07-5.66-6.47-3.72-11.77-9.25-15.29-15.91-3.81-7.32-5.72-15.51-5.55-23.78-.17-8.26,1.68-16.45,5.38-23.79,3.43-6.67,8.68-12.16,15.13-15.83,6.86-3.81,14.58-5.72,22.39-5.56,7.56-.14,15.03,1.73,21.68,5.41,6.26,3.52,11.41,8.79,14.83,15.2,3.63,6.92,5.45,14.67,5.3,22.52.02,2.98-.18,5.97-.63,8.92M783.41,129.84c.13-6.16-2.45-12.06-7.03-16.07-4.74-4.1-10.79-6.25-17-6.05-5.81-.12-11.44,2.01-15.76,5.97-4.48,4.2-7.26,9.96-7.8,16.15h47.59Z" style="stroke-width: 0px;" class="letter"></path>
							<path d="M585.27,117.44c-11.41,19.82-28.8,30.8-34.85,34.62-6.61,4.04-13.52,7.59-20.65,10.62-4.84,2.05-9.79,3.79-14.84,5.22-3.75,1.06-5.77,1.44-12.71,3.22-4.71,1.2-8.54,2.26-11.03,2.95-1.97-5.43-4.22-10.75-6.74-15.95-6.29-13-9.74-15.85-14.89-26.64-4-8.1-6.9-16.7-8.63-25.57-2.08-9.84-2.39-19.97-.93-29.92,2.24-14.75,7.97-25.02,11.28-30.84,3.69-6.49,9.83-17.26,21-25.89,2.82-2.18,31.16-23.35,62.92-11.56,26.17,9.7,35.7,34.75,37.83,40.31,12.82,33.68-4.72,64.16-7.76,69.44h0Z" style="fill: #f0513c; stroke-width: 0px;"></path>
							<path d="M599.31,53.92c-2.51-9.36-6.61-18.14-12.17-26.1-8.62-12.3-20.77-21.28-34.17-25.3-12.25-3.68-25.8-3.34-39.16.98-8.03,2.59-15.59,6.38-22.49,11.25-11.38,8.08-18.88,19.83-24.14,29.14-3.44,5.93-6.25,12.24-8.38,18.78-2.71,8.64-4.02,17.62-3.89,26.71.33,14.03,3.44,27.62,9.26,40.41,2.56,5.6,5.52,11.08,8.79,16.25,4.08,6.55,7.7,13.44,10.75,20.48,1.29,2.97,2.49,6.03,3.6,9.07.03.08.06.15.1.23.69,1.5,2.18,2.42,3.78,2.42.25,0,.5-.02.75-.06.08-.02.17-.03.25-.06,3.95-1.1,8.01-2.11,11.95-3.1,3.51-.88,7.14-1.79,10.7-2.76,6.38-1.74,12.67-3.98,18.7-6.66,13.86-6.14,25.76-13.54,35.34-21.96,7.9-6.87,14.61-14.94,19.94-24,.02-.03.04-.06.06-.1,4.1-7.08,7.31-14.66,9.57-22.54,4.17-14.74,4.46-29.64.87-43.09v-.02ZM592.29,86.57c-1.67,9.12-4.74,17.89-9.12,26.06-.48.88-.97,1.74-1.47,2.61-.03.05-.06.1-.08.15-7.03,12.09-17.41,22.68-30.83,31.46-10.99,7.23-22.94,12.81-35.5,16.59-2.57.76-5.25,1.42-7.85,2.06l-2.52.62c-3.62.89-7.36,1.87-11.17,2.9-2.39-6.18-5.16-12.23-8.26-18.04-1.6-3-3.39-5.9-5.11-8.71-1.52-2.47-3.09-5.02-4.5-7.59-6.08-11.01-10.07-22.91-11.84-35.36-2.11-14.86.1-29.92,6.36-43.56,2.33-4.95,5-9.79,7.96-14.45,4.02-6.63,9.05-12.54,14.9-17.52,4.84-3.92,10.18-7.14,15.93-9.59,8.74-3.88,17.72-5.88,26.28-5.88,1.87,0,3.71.1,5.53.28,13.14,1.49,25.26,7.73,34.12,17.56,5.88,6.55,10.45,14.04,13.59,22.29,4.69,12.13,5.91,25.31,3.57,38.11v-.02Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M489.56,178.53c.67.24,1.42-.1,1.67-.77.24-.67-.1-1.42-.77-1.67-.68-.25-1.42.1-1.67.77-.24.68.1,1.43.77,1.67" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M495.19,172.38c-.47-1.02-1.3-1.79-2.36-2.17-2.14-.77-4.52.31-5.33,2.43-1.38,3.39-3.19,6.59-5.46,9.57-2.17,3.02-4.55,6.01-6.86,8.88l-1.9,2.38c-9.33,11.75-15.98,21.92-20.92,32.02-4.16,8.55-7.48,17.53-9.89,26.68-.4,1.48,0,3.01,1.08,4.09.84.84,1.95,1.29,3.07,1.29.35,0,.71-.05,1.05-.14,1.37-.37,2.41-1.44,2.79-2.86,3.84-14.56,9.99-28.3,18.27-40.86,3.55-5.34,7.61-10.5,11.55-15.47,2.58-3.27,5.26-6.65,7.74-10.03,2.99-3.82,5.42-8.03,7.29-12.61.39-1.06.35-2.19-.13-3.21h.02Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M492.42,83.47c.67.24,1.42-.1,1.67-.77.24-.67-.1-1.42-.77-1.67-.68-.25-1.42.1-1.67.77-.24.67.1,1.42.77,1.67Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M491.13,81.72c2.53,7.34,10.52,11.22,17.85,8.67,1.33-.47,2.57-1.12,3.71-1.95,1.36-.98-.31-2.98-1.66-2.01-2.97,2.11-6.76,2.68-10.22,1.56-3.45-1.18-6.16-3.9-7.31-7.37-.55-1.59-2.9-.48-2.36,1.1" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M493.1,82.51c-1.84.18-3.62.83-5.14,1.89-.56.45-.63,1.27-.17,1.83.23.27.55.44.89.47.34,0,.67-.1.94-.29.09-.06.17-.11.26-.17l-.28.18c.69-.44,1.43-.78,2.2-1.02l-.31.1c.68-.21,1.38-.35,2.08-.41.34-.06.64-.25.83-.53.2-.29.27-.64.22-.99-.06-.33-.25-.63-.52-.83-.29-.19-.64-.27-.99-.23h0Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M495.64,87.5c-.26.73-.56,1.46-.93,2.14l.16-.31c-.32.62-.69,1.21-1.09,1.78-.42.59-.28,1.4.31,1.81.58.42,1.4.28,1.81-.3.93-1.31,1.67-2.73,2.19-4.23.25-.67-.1-1.42-.76-1.67-.68-.24-1.42.1-1.67.77h-.02Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M501.44,91.1c.02.24.04.48.06.72l-.02-.34c.05,1.01,0,2.02-.19,3-.02.34.08.68.29.94.46.56,1.27.63,1.83.18.26-.23.42-.56.47-.9.23-1.34.26-2.71.11-4.05-.06-.33-.25-.63-.52-.83-.29-.2-.64-.28-.99-.22-.34.06-.64.24-.83.52-.19.29-.27.64-.23.99l.02-.02Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M507.42,90.56c.17.22.32.44.47.67l-.18-.27c.39.63.71,1.31.93,2.02l-.1-.31c.09.27.15.56.22.84.06.33.26.63.53.83.28.2.64.28.98.23.34-.06.64-.25.83-.53.2-.29.27-.64.22-.99-.31-1.49-.96-2.9-1.89-4.12-.46-.56-1.27-.63-1.83-.18-.56.46-.63,1.28-.18,1.83Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M527.1,93.57c.26,4.5,3.42,8.22,7.19,10.38,3.92,2.24,9.2,4.18,13.73,2.93,1.6-.44,1.16-3.01-.46-2.56-3.85,1.06-8.41-.58-11.76-2.48-3.27-1.86-5.93-4.82-6.15-8.72-.09-1.67-2.65-1.19-2.55.46h0Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M527.88,97.71c-.15.19-.31.38-.48.56l.23-.24c-.49.52-1.05.98-1.65,1.36l.28-.18c-.48.31-1,.56-1.53.77-.65.3-.94,1.07-.64,1.73.31.64,1.07.92,1.72.64,1.65-.64,3.09-1.72,4.18-3.11.41-.59.27-1.39-.31-1.81-.59-.4-1.38-.27-1.81.31h0Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M531.39,101.91c-.02.06-.06.1-.08.15l.16-.31c-.7,1.33-1.48,2.62-2.34,3.85-.42.59-.28,1.4.3,1.81.59.42,1.4.28,1.82-.3.95-1.39,1.81-2.82,2.58-4.31.12-.32.1-.69-.04-1-.31-.65-1.08-.94-1.73-.63-.3.16-.53.42-.67.73" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M536.06,105.02l-1.25,4.09c-.25.67.1,1.42.77,1.67.67.24,1.42-.1,1.67-.77l1.25-4.09c.25-.68-.1-1.43-.76-1.67-.68-.24-1.42.11-1.67.77" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M541.41,106.65c.07,1.47.27,2.93.58,4.37.13.7.8,1.18,1.5,1.05.7-.13,1.17-.81,1.05-1.51-.31-1.44-.5-2.9-.58-4.37-.06-.34-.25-.64-.53-.83-.29-.2-.64-.28-.99-.23-.33.06-.63.25-.83.53l-.17.31c-.08.21-.1.45-.06.67h.02Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M491.31,82.27l-3.93,2.56c-.27.23-.43.55-.47.9-.02.34.07.67.29.94.23.27.55.44.89.47.34,0,.67-.09.94-.29l3.93-2.56c.27-.23.43-.55.47-.89.02-.34-.07-.68-.29-.94-.23-.27-.55-.44-.89-.47-.34,0-.67.1-.94.29Z" style="fill: #241714; stroke-width: 0px;"></path>
							<path d="M496.92,109.6c1.3,8.46,9.18,14.26,17.63,12.96,2.9-.44,5.6-1.7,7.8-3.62.55-.46.64-1.28.18-1.83-.46-.56-1.27-.64-1.83-.18-5.33,4.7-13.47,4.18-18.16-1.18-1.63-1.86-2.7-4.16-3.07-6.62-.12-.71-.8-1.18-1.5-1.06-.71.13-1.17.8-1.05,1.51h0Z" style="fill: #241714; stroke-width: 0px;"></path>
						</svg>
</template>